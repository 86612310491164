export default {
  props: {
    profilePicture: {
      type: String,
      default: null,
      required: false
    },
    initials: {
      type: String,
      default: ''
    },
    profilePictureOnly: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 32
    },
    left: {
      type: Boolean,
      default: false
    }
  },
  name: 'Avatar',
  data: () => ({}),
  computed: {
    showAvatar() {
      return this.profilePicture || !this.profilePicture;
    },
    initialsClass() {
      return {
        'white--text text-uppercase brown elevation-2': !this.profilePicture
      };
    }
  }
};