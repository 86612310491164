import logo from '@/assets/images/logo.png';
export default {
  __name: 'AppLogo',
  props: {
    size: {
      type: Number,
      default: 30
    }
  },
  setup(__props) {
    const props = __props;
    return {
      __sfc: true,
      props,
      logo
    };
  }
};