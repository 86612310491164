/* unplugin-vue-components disabled */import AppLayout from '@/layouts/AppLayout.vue';
import { Loader, Alerts } from '@/components';
export default {
  name: 'App',
  data: () => ({}),
  components: {
    AppLayout,
    Loader,
    Alerts
  },
  async created() {
    await this.getStatus();
  },
  methods: {
    ...mapActions('status', ['getStatus'])
  }
};