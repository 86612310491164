import { VImg } from 'vuetify/lib/components/VImg';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VImg, {
    attrs: {
      "src": _setup.logo,
      "contain": "",
      "inline": "",
      "height": _setup.props.size,
      "width": _setup.props.size
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };