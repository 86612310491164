import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.showAvatar ? _c(VAvatar, {
    class: _vm.initialsClass,
    attrs: {
      "left": _vm.left,
      "size": _vm.size
    }
  }, [_vm.profilePicture ? _c(VImg, {
    attrs: {
      "src": `${_vm.profilePicture}`
    }
  }) : _c('h5', [_vm._v(_vm._s(_vm.initials))])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };