export default {
  name: 'AppLayout',
  props: {
    defaultLayout: {
      type: String,
      default: 'DefaultLayout'
    }
  },
  computed: {
    layout() {
      const layout = this.$route.meta.layout || this.defaultLayout;
      return () => import(`@/layouts/${layout}.vue`);
    }
  }
};