import { mapState } from 'vuex';
import Alert from '@/components/utils/Alert';
export default {
  props: {},
  components: {
    Alert
  },
  name: 'Alerts',
  data: () => ({}),
  methods: {},
  computed: {
    ...mapState('alerts', ['alerts'])
  }
};