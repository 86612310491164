import Vue from 'vue'
import axios from '@/mixins/axios'

const scaleNumber           = (n, target, concat = '') => {
    if (target >= 1_000 && target < 1_000_000) {
        n /=1_000
        concat ='K'
    } else if (n >= 1_000_000) {
        n /=1_000_000
        concat ='M'
    }

    return { n, concat }
}

const formatDate2 = (date, includeTime = false) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' }
    if (includeTime) {
        options.hour = 'numeric'
        options.minute = 'numeric'
    }
  
    date = new Date(date)
    return (new Intl.DateTimeFormat('en-US', options)).format(date)
}

const capitalizeFirstLetter = (s) => s.charAt(0).toUpperCase() + s.slice(1) 
const formatNumber          = (n, type = null, opts = { concat: '', target: null, maximumFractionDigits: 2,}) => {
	if (isNaN(n)) return n
    if (['currency', 'percent'].includes(type)) {
        opts.style = type,
        opts.minimumFractionDigits = 0
        opts.maximumFractionDigits = 2
        opts.currency = 'USD'
    } else if (type === 'percent2') {
        opts.concat = '%'
    }

    const nScaled = scaleNumber(n, opts.target ?? n)
    nScaled.concat += opts.concat ?? ''
    return `${new Intl.NumberFormat('en-US', opts).format(nScaled.n)}${nScaled.concat}`
}
const formatObjective = (objective, key) => {
    const type = getFormatKey(objective, key)
    return formatNumber(objective[key], type)
}

const getFormatKey = ({ isFinancial, isNumeric, isProject, againstTarget, achievedVsTarget }, key)=> {
    if ((key === 'completed' && (isProject || againstTarget)) || (isNumeric && !(isProject) && ['target', 'completed', 'result'].includes(key))) {
        return null
    } else if (key === 'progress') {
        return 'percent'
    } else if (key === 'weight' || (!isFinancial && !isNumeric) || (isProject) || (achievedVsTarget && key == 'target')) {
        return 'percent2'
    } else {
        return 'currency'
    }
}

// const getFormatKey = ({ isFinancial, isNumeric, isProject, againstTarget }, key)=> {
//     if (key === 'completed')
// }

Vue.mixin({
    data: () => ({
        rulesObj: {
            required({ label }) { 
                const ao = ['Razón', 'Meta'].includes(label) ? 'a' : 'o'
                return (v) => (!!v || v === 0) || `${label} es requerid${ao}`
            },
            noNewLine({ label }) {
                return (v) => !/^(\r\n|\n|\r)+$/.test(v) || `${label} es requerido`
            },
            numeric({ label }) {
                return (v) => !isNaN(String(v).replace(/[^\d.]/g, '')) || `${label} debe ser un número válido`
            },
            weight({ limit, total, label }) {
                return [
                    (v) => parseFloat(String(v).replace(/[^\d.]/g, '')) >= 0 && parseFloat(String(v).replace(/[^\d.]/g, '')) <= 100 || `${label} debe ser un número entre 0 y 100`,
                    (v) => (limit - (total + parseFloat(String(v).replace(/[^\d.]/g, '')))) >= 0 &&  (limit - (total + parseFloat(String(v).replace(/[^\d.]/g, '')))) <= 100 || `El ${label} total no debe exceder 100`
                ]
            },
            stringLimit({ label, limit = 255 }) {
                return (v) => !v || v.length <= limit || `${label} no debe exeder los ${limit} caracteres`
            },
            fileSize() {
				return (v) => {
					return (v?.size ?? 0) <= 5_000_000 || 'Tamaño de archivo no debe exceder 5MB'
				}
			
				
                // return (v) => !!v || (v?.size ?? 0) <= 5_000_000 || 'Tamaño de archivo no debe exceder 5MB'
            },
            validJSON() {
                return true
            }
        },
    }),
    methods: {
        getRules(item, rules) {
            return rules.flatMap((r) => this.rulesObj[r](item))
        },
        capitalizeFirstLetter,
        formatObjective,
        scaleNumber,
        isObject(o) {
            return Object.prototype.toString.call(o) === '[object Object]'
        },
        isString(value) {
            return typeof value === 'string' || value instanceof String
        },
        getCurrentDate: () => new Date().toLocaleString('sv', {timeZone: 'America/Puerto_Rico'}).slice(0,10),
        zeroPad: (n, pad = 2) => String(n).padStart(pad, '0'),
        formatNumber, 
        getFormatKey,  
        getChartData: (data) => ({ labels: data?.labels ?? [], datasets: [data ?? []], }),
        titleFormatter: (s, exc = ['de ']) => s.replace(/(.+?)([\s,.!?:)([\]]+)/g, (w) => exc.includes(w) ? w : capitalizeFirstLetter(w)).replaceAll(/[.](\s)?/g,''),
        formatDate: (date, opts= { time: null, weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) => {
            // console.log(date)
            if (opts.time) {
                date = `${date} ${opts.time}`
                opts = {...opts, hour: '2-digit', minute: '2-digit'}
            } else if(!date.includes('T')) {
                date = `${date}T04:00:00`
            }

            return (new Date(date)).toLocaleDateString('es-PR', opts)
        },
        getShowIcon: (status = false) => status ? 'mdi-eye-outline' : 'mdi-eye-off-outline', 
        removeItem (item, src, key = 'id') {
            const idx = src.findIndex((v) => v === item[key])
            if(idx >= 0) {
                src.splice(idx, 1)
            }
        },
        downloadFile(url, id, fileName) {
try {
    axios.get(`${url}/${id}/download`, { responseType: 'blob', })
    .then((res) => {
        const file     = window.URL.createObjectURL(new Blob([res.data]))
        const docUrl   = document.createElement('a')
        docUrl.href    = file
        docUrl.setAttribute('download', fileName)
        document.body.appendChild(docUrl)
        docUrl.click()
    }).catch((err) => {
        // console.log(err)
    })
} catch (err) {
    // console.log(err)
}
        },
        getTimeAgo(datetime, includeTime = false) {
            const currentDatetime = new Date()
            const timestamp = new Date(datetime)
            const secondsAgo = Math.floor((currentDatetime - timestamp) / 1000)
          
            if (secondsAgo < 60) {
                return `hace ${secondsAgo}s`
            } else if (secondsAgo < 3600) {
                const minutesAgo = Math.floor(secondsAgo / 60)
                return `hace ${minutesAgo}m`
            } else if (secondsAgo < 86400) {
                const hoursAgo = Math.floor(secondsAgo / 3600)
                return `hace ${hoursAgo}h`
            } else if (secondsAgo < 604800) { // 7 days in seconds
                const daysAgo = Math.floor(secondsAgo / 86400)
                return `hace ${daysAgo}d`
            } else if (secondsAgo < 2419200) { // 4 weeks in seconds
                const weeksAgo = Math.floor(secondsAgo / 604800)
                return `hace ${weeksAgo}w`
            } else {
                return `en ${formatDate2(datetime, includeTime)}`
            }
        },
        sliceText(text, n = 20) {
            return text.length <= n ? text : text.slice(0, n) + '...'
        },
    }
})

