import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VBreadcrumbs, {
    staticClass: "pa-0 font-weight-bold",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function ({
        item
      }) {
        return [_c(VBreadcrumbsItem, {
          attrs: {
            "exact": item.exact,
            "link": item.link,
            "to": item.to,
            "disabled": item.disabled
          }
        }, [_vm._v(_vm._s(_vm.sliceBreadcrumb(item.text)))])];
      }
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };