import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.VUE_APP_BASE_URL,
	routes: [{
		path        : '/',
		name        : 'dashboard',
		component   : () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
		meta		: { requiresAuth: true, title: 'Dashboard' }
	}, {
		path        : '/perfil/:username',
		name        : 'profile',
		component   : () => import(/* webpackChunkName: "profile" */ '@/views/users/Profile.vue'),
		props       : true,
		meta		: { 
            requiresAuth: true, 
            title: 'Perfil',
            breadcrumbs: [
                { text: 'Dashboard', link: true, disabled: false, to: { path: '/' }, },
                { text: 'Perfil', link: true, disabled: true, to: '/perfil', },
            ],
        }
	}, {
		path        : '/objetivos/:id',
		name        : 'objectives',
		component   : () => import(/* webpackChunkName: "objectives" */ '@/views/objectives/Detail.vue'),
		props       : true,
		meta		: { 
            requiresAuth: true, 
            title: 'Objetivo',
            breadcrumbs: [
                { text: 'Dashboard', link: true, disabled: false, to: { path: '/' }, },
            ],
        }
	}, {
		path        : '/equipo/:username',
		name        : 'team',
		component   : () => import(/* webpackChunkName: "team" */ '@/views/Team.vue'),
		props       : true,
		meta		: { 
            requiresAuth: true, 
            requiresSupervisor: true, 
            title: 'Equipo' 
        }
	}, {
		path        : '/conversaciones',
		name        : 'conversationIndex',
		component   : () => import(/* webpackChunkName: "conversationIndex" */ '@/views/conversations/Index.vue'),
		props       : false,
		meta		: { 
            requiresAuth: true, 
            title: 'Conversaciones',
            breadcrumbs: [
                { text: 'Dashboard', link: true, disabled: false, to: { path: '/' }, },
                { text: 'Conversaciones', link: true, disabled: true, exact: true, to: '/conversaciones', },
            ],
        }
	}, {
		path        : '/conversaciones/:id',
		name        : 'conversationDetail',
		component   : () => import(/* webpackChunkName: "conversationDetail" */ '@/views/conversations/Detail.vue'),
		props       : true,
		meta		: { 
            requiresAuth: true, 
            title: 'Conversaciones',
            breadcrumbs: [
                { text: 'Dashboard', link: true, disabled: false, to: { path: '/' }, },
                { text: 'Conversaciones', link: true, disabled: false, exact: true, to: '/conversaciones', },
            ],
        }
	}, {
		path        : '/reconocimientos',
		name        : 'acknowledgmentIndex',
		component   : () => import(/* webpackChunkName: "acknowledgment" */ '@/views/acknowledgments/Index.vue'),
		meta		: { 
            requiresAuth: true, 
            title: 'Reconocimientos',
            breadcrumbs: [
                { text: 'Dashboard', link: true, disabled: false, to: { path: '/' }, },
                { text: 'Reconocimientos', link: true, disabled: true, to: '/reconocimientos', },
            ],
        }
	}, {
		path        : '/admin/reconocimientos',
		name        : 'acknowledgmentCreate',
		component   : () => import(/* webpackChunkName: "acknowledgmentsCreate" */ '@/views/acknowledgments/Create.vue'),
		meta		: { 
            requiresAuth: true, 
            requiresAdmin: true, 
            title: 'Admin - Reconocimientos',
            breadcrumbs: [
                { text: 'Admin', link: true, disabled: false, to: { path: '/' }, },
                { text: 'Reconocimientos', disabled: true, to: '/admin/reconocimientos', },
            ],
        }
	}, {
		path        : '/admin/usuarios',
		name        : 'userIndex',
		component   : () => import(/* webpackChunkName: "users" */ '@/views/users/Index.vue'),
		meta		: { 
            requiresAuth: true, 
            requiresAdmin: true, 
            title: 'Admin - Usuarios',
            breadcrumbs: [
                { text: 'Admin', link: true, disabled: false, to: { path: '/' }, },
                { text: 'Usuarios', disabled: true, to: '/admin/usuarios', },
            ],
        }
	}, {
		path        : '/admin/csytab',
		name        : 'csytabIndex',
		component   : () => import(/* webpackChunkName: "csytab" */ '@/views/csytab/Index.vue'),
		meta		: { 
            requiresAuth: true, 
            requiresAdmin: true, 
            title: 'Admin - Configuración',
            breadcrumbs: [
                { text: 'Admin', link: true, disabled: false, to: { path: '/' }, },
                { text: 'Configuración', disabled: true, to: '/admin/csytab', },
            ],
        }
	}, { 
		path		: '/login',
		name		: 'login',
		component	: () => import(/* webpackChunkName: "login" */ '@/views/Login'),
		meta		: { 
            requiresAuth: false, 
            title: 'Login',
            layout: 'LoginLayout',
        } 
	}]
})

router.beforeEach((to, _, next) => {
    const { requiresAuth, requiresAdmin, requiresSupervisor }   = to.meta
    const { username, isLoggedIn, isAdmin, isSupervisor }       = store.getters
    const isNotAdmin        = requiresAdmin && !isAdmin
    const isNotSupervisor   = requiresSupervisor && !isSupervisor && !isAdmin
    const authNotRequired   = !requiresAuth && isLoggedIn

	console.log()

    if(requiresAuth && !isLoggedIn) {
        next({ path: '/login', query: { nextUrl: to.fullPath } })
    } else if(isNotAdmin || isNotSupervisor || authNotRequired) {
        next({ path: `/perfil/${username}` })
    } else {
        next()
    }
})

router.afterEach((to) => {
    Vue.nextTick(() => { 
        document.title = to.meta.title || 'Vales' 
    })
})

export default router
