import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VSlideXTransition, {
    attrs: {
      "hide-on-leave": true
    }
  }, [_vm.showSidebar ? _c(VCol, {
    staticClass: "sidebar py-7 px-0 box-shadow",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('div', {
    staticClass: "sticky"
  }, _vm._l(_vm.allowedRoutes, function (route, i) {
    return _c('div', {
      key: route.id,
      attrs: {
        "id": route.id
      }
    }, [route.sectionHeader && i !== 0 ? _c(VDivider, {
      staticClass: "mt-7 mb-3 mx-7"
    }) : _vm._e(), route.sectionHeader ? _c('div', {
      staticClass: "nav-link font-bold"
    }, [_c('span', [_vm._v(_vm._s(route.title))])]) : _c('RouterLink', {
      staticClass: "nav-link font-semibold",
      attrs: {
        "to": route.to
      }
    }, [_c(VIcon, {
      staticClass: "mr-3"
    }, [_vm._v(_vm._s(route.icon))]), _c('span', [_vm._v(_vm._s(route.title))])], 1)], 1);
  }), 0)]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };