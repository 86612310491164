import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/mixins/axios'
import router from '@/router'
import VuexPersist from 'vuex-persist'
import { alerts } from './modules/alerts'
import { loader } from './modules/loader'
import { status } from './modules/status'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		isLoggedIn: false,
		user: {},
		search: '',
		year: new Date().getFullYear(),
		years: [new Date().getFullYear()],
		breadcrumbs: [],
		showSidebar: true,
		showAgreementUser: false,
		showCompletedAgreements: false,
		showCompletedConversations: false,
		showCompletedInitiatives: false,
		showCompletedStages: false,
		showGivenAcknowledgments: false,

		showCompetencies: true,
		evaluationPeriod: 'mid-year',
		domain: '@prsupplies.com',
		domains: [
			{ text: 'Puerto Rico Supplies', value: '@prsupplies.com', },
			{ text: 'Glamour', value: '@glamourpr.com', },
			{ text: 'Agro Produce', value: '@agroproducepr.com', }
		],
	},
	modules: { alerts, loader, status, },
	actions: {
		login({ commit }, { user }) {
			return new Promise((resolve, reject) => {
				axios.post('/auth/login', { ...user })
					.then((res) => {
						commit('login', { ...res.data, })
						resolve(res)
					})
					.catch((err) => {
						reject(err)
					})
			})
		},
		logout({ commit }, { redirect }) {
			return new Promise((resolve, reject) => {
				axios.post('/auth/logout')
					.then((res) => {
						commit('logout', { redirect })
						resolve(res)
					})
					.catch((err) => {
						reject(err)
					})
			})
		},
		linkAccount({ commit }, { user }) {
			return new Promise((resolve, reject) => {
				axios.post('/auth/link', { ...user })
					.then((res) => {
						resolve(res)
					})
					.catch((err) => {
						reject(err)
					})
			})
		},
		updateDomain({ commit }, payload) {
			commit('updateDomain', payload)
		},
		updateSearch({ commit }, payload) {
			commit('updateSearch', payload)
		},
		updateYear({ commit }, payload) {
			commit('updateYear', payload)
		},
		updateBreadcrumbs({ commit }, payload) {
			commit('updateBreadcrumbs', payload)
		},
		toggleSidebar({ commit }) {
			commit('updateSidebar')
		},
		updateAgreementUser({ commit }) {
			commit('updateAgreementUser')
		},
		updateCompletedAgreements({ commit }, payload) {
			commit('updateCompletedAgreements', payload)
		},
		updateCompletedConversations({ commit }, payload) {
			commit('updateCompletedConversations', payload)
		},
		updateCompletedInitiatives({ commit }, payload) {
			commit('updateCompletedInitiatives', payload)
		},
		updateCompletedStages({ commit }, payload) {
			commit('updateCompletedStages', payload)
		},
		updateGivenAcknowledgments({ commit }, payload) {
			commit('updateGivenAcknowledgments', payload)
		},
		updateEvaluationPeriod({ commit }, payload) {
			commit('updateEvaluationPeriod', payload)
		},
		updateCompetencies({ commit }) {
			commit('updateCompetencies')
		},
	},
	mutations: {
		login(state, { user, years }) {
			state.isLoggedIn = true
			state.user = user
			state.years = years
			// state.year = years[0] ?? state.year
			const urlParams = new URLSearchParams(window.location.search)
			const baseUrl = user.isSupervisor ? 'equipo' : 'perfil'
			const redirect = urlParams.get('redirect') || `/${baseUrl}/${user.username}`
			router.push({ path: redirect })
		},
		logout(state, { redirect = undefined }) {
			state.isLoggedIn = false
			state.user = {}

			router.push({ path: '/login', query: { redirect } })
		},
		updateDomain(state, payload) {
			state.domain = payload
		},
		updateSearch(state, payload) {
			state.search = payload
		},
		updateYear(state, payload) {
			state.year = payload
		},
		updateBreadcrumbs(state, payload) {
			state.breadcrumbs = payload
		},
		updateSidebar(state) {
			state.showSidebar = !state.showSidebar
		},
		updateAgreementUser(state) {
			state.showAgreementUser = !state.showAgreementUser
		},
		updateCompletedAgreements(state, payload) {
			state.showCompletedAgreements = payload
		},
		updateCompletedConversations(state, payload) {
			state.showCompletedConversations = payload
		},
		updateCompletedInitiatives(state, payload) {
			state.showCompletedInitiatives = payload
		},
		updateCompletedStages(state, payload) {
			state.showCompletedStages = payload
		},
		updateGivenAcknowledgments(state, payload) {
			state.showGivenAcknowledgments = payload
		},
		updateEvaluationPeriod(state, payload) {
			state.evaluationPeriod = payload
		},
		updateCompetencies(state) {
			state.showCompetencies = !state.showCompetencies
		},
	},
	getters: {
		search: (s) => s.search,
		year: (s) => s.year,
		years: (s) => s.years,
		breadcrumbs: (s) => s.breadcrumbs,
		showSidebar: (s) => s.showSidebar,
		showCompletedAgreements: (s) => s.showCompletedAgreements,
		showCompletedConversations: (s) => s.showCompletedConversations,
		showAgreementUser: (s) => s.showAgreementUser,
		showCompletedInitiatives: (s) => s.showCompletedInitiatives,
		showGivenAcknowledgments: (s) => s.showGivenAcknowledgments,
		evaluationPeriod: (s) => s.evaluationPeriod,

		showCompletedStages: (s) => s.showCompletedStages,
		showCompetencies: (s) => s.showCompetencies,
		domain: (s) => s.domain,
		domains: (s) => s.domains,
		user: (s) => s.user,
		userId: (s) => s.user?.id,
		username: (s) => s.user?.username,
		firstName: (s) => s.user?.firstName,
		fullName: (s) => s.user?.fullName,
		initials: (s) => s.user?.initials || '',
		profilePicture: (s) => s.user?.profilePicture,
		isLoggedIn: (s) => s.isLoggedIn,
		isAdmin: (s) => s.user.admin,
		isSupervisor: (s) => s.user.isSupervisor,
		departmentName: (s) => s.user?.department?.text || ''
	},
	plugins: [(new VuexPersist({ key: 'valess', storage: window.localStorage })).plugin],
})
