import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VToolbar, {
    staticClass: "white box-shadow z-100 px-5",
    attrs: {
      "cols": "12",
      "id": "navbar",
      "dense": "",
      "height": "60"
    }
  }, [_c(VAppBarNavIcon, {
    on: {
      "click": _vm.toggleSidebar
    }
  }), _c('RouterLink', {
    staticClass: "black--text d-flex align-center",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "mx-3",
    attrs: {
      "src": require("@/assets/images/logo.png"),
      "width": "30",
      "alt": "logo"
    }
  }), _c('h2', [_vm._v("Vales")])]), _c(VSpacer), _c('div', {
    staticClass: "search mr-3"
  }), _c('div', {
    staticClass: "text-right"
  }, [_c(VMenu, {
    staticStyle: {
      "z-index": "401"
    },
    attrs: {
      "open-on-hover": "",
      "bottom": "",
      "offset-y": "",
      "transition": "slide-y-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VBtn, _vm._g(_vm._b({
          staticClass: "btn mr-3",
          attrs: {
            "text": "",
            "rounded": ""
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, {
          attrs: {
            "size": "20",
            "color": "#606060"
          }
        }, [_vm._v("$calendar")]), _c('span', {
          staticClass: "font-weight-bold mx-1 s-text"
        }, [_vm._v(_vm._s(_vm.year))])], 1)];
      }
    }])
  }, [_c(VList, {
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, [_c('div', {
    staticClass: "font-weight-bold mb-1"
  }, [_vm._v("Año")]), _c(VListItemGroup, {
    attrs: {
      "dense": "",
      "color": "primary"
    },
    model: {
      value: _vm.selectedYear,
      callback: function ($$v) {
        _vm.selectedYear = $$v;
      },
      expression: "selectedYear"
    }
  }, _vm._l(_vm.years, function (year) {
    return _c(VListItem, {
      key: year,
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          _vm.localYear = year;
        }
      }
    }, [_c(VListItemTitle, {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(year))])], 1);
  }), 1)], 1)], 1), _c('UserMenu')], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };