import axios from '@/mixins/axios'
export const status = {
    namespaced: true,
    state: {
        status: {}
    },
	actions: {
		getStatus({ commit },) {
			return new Promise((resolve, reject) => {
				axios.get('/settings')
                .then((res) => {
                    commit('setStatus', { ...res.data, })
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
			})
		}, 
    },
    mutations: {
        setStatus(state, { status }) {
            state.status = status
        },
    },
    getters: {
        getKeyByStatus: (state) => (status = 'default', key = 'color') => {
            const s = state.status[status]
            switch (key) {
                case 'text' : return s ? s[key] : 'Default'
                default     : return s?.ext[key] ?? ''

            }
        }
    }
}