import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VMenu, {
    attrs: {
      "open-on-hover": "",
      "bottom": "",
      "offset-y": "",
      "transition": "slide-y-transition",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c('div', _vm._g(_vm._b({
          staticClass: "d-inline-block"
        }, 'div', attrs, false), on), [_c('Avatar', {
          attrs: {
            "profilePicture": _vm.profilePicture,
            "initials": _vm.initials,
            "size": 30
          }
        })], 1)];
      }
    }])
  }, [_c(VCard, {
    staticClass: "box-shadow",
    attrs: {
      "width": "175px"
    }
  }, [_c(VCardTitle, {
    staticClass: "d-flex align-center pb-0 px-2"
  }, [_c('Avatar', {
    staticClass: "mr-3",
    attrs: {
      "profilePicture": _vm.profilePicture,
      "initials": _vm.initials
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.username))])], 1), _c(VCardText, {
    staticClass: "black--text px-2 justify-center pb-0"
  }, [_c(VDivider, {
    staticClass: "my-2"
  }), _c(VList, {
    staticClass: "px-0",
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, [_c(VListItem, {
    on: {
      "click": _vm.logout
    }
  }, [_c(VListItemIcon, {
    staticClass: "px-0 mx-0"
  }, [_c(VIcon, {
    attrs: {
      "small": "",
      "color": "black"
    }
  }, [_vm._v("mdi-logout-variant")])], 1), _c(VListItemTitle, {
    staticClass: "font-weight-bold"
  }, [_vm._v("Logout")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };