/* unplugin-vue-components disabled */export default {
  props: {
    username: {
      type: String,
      required: true
    }
  },
  name: 'Sidebar',
  data: inst => ({
    routes: [{
      title: 'Menú',
      sectionHeader: true,
      requiresAdmin: false
    }, {
      title: 'Equipo',
      to: `/equipo/${inst.username}`,
      icon: 'mdi-account-supervisor-circle-outline',
      requiresSupervisor: true
    }, {
      title: 'Dashboard',
      to: '/',
      id: 'dashboard',
      icon: 'mdi-view-dashboard-outline'
    }, {
      title: 'Perfil',
      to: `/perfil/${inst.username}`,
      icon: 'mdi-account-circle-outline'
    }, {
      title: 'Conversaciones',
      to: '/conversaciones',
      icon: 'mdi-comment-account-outline'
    }, {
      title: 'Reconocimientos',
      to: '/reconocimientos',
      icon: 'mdi-star-outline'
    }, {
      title: 'Admin',
      sectionHeader: true,
      requiresAdmin: true
    },
    // { title: 'Reconocimientos', to: '/admin/reconocimientos', icon: 'mdi-star-settings-outline', requiresAdmin: true, },
    {
      title: 'Usuarios',
      to: '/admin/usuarios',
      icon: 'mdi-account-supervisor-circle-outline',
      requiresAdmin: true
    }, {
      title: 'Configuración',
      to: '/admin/csytab',
      icon: 'mdi-cog-outline',
      requiresAdmin: true
    }]
  }),
  computed: {
    ...mapGetters(['isSupervisor', 'isAdmin', 'showSidebar']),
    allowedRoutes() {
      return this.routes.filter(route => route.requiresSupervisor === this.isSupervisor || route.requiresAdmin === this.isAdmin || !route.requiresSupervisor && !route.requiresAdmin);
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
        case 'lg':
        case 'xl':
          return 'h-100-60';
        default:
          return 'pb-0';
      }
    }
  }
};