import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            info: 'mdi-information-outline',
            success: 'mdi-check-circle-outline',
            checkboxOn: 'mdi-check-circle-outline',
            checkboxOff: 'mdi-circle-outline',
            user: 'mdi-account-outline',
            password: 'mdi-lock-outline',
            domain: 'mdi-printer-pos-outline',
            login: 'mdi-login',
            calendar: 'mdi-calendar',

        }
    },
    defaults: {
        VBtn: {
            variant: 'tonal',
            class: 'btn'
        },
        VCardTitle: {
            class: 'd-flex align-center justify-space-between font-weight-bold pb-0'
        },
        VListItemTitle: {
            class: 'font-weight-bold'
        },
        VTextField: {
            variant: 'outlined',
            density: 'compact',
        },
        VSelect: {
            variant: 'outlined',
            density: 'compact',
        },
        VDataTable: {
            density: 'compact',
        },
    },
});

