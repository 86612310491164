import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "persistent": "",
      "width": "300",
      "content-class": "rounded-0"
    },
    model: {
      value: _vm.loading,
      callback: function ($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_c(VCard, {
    staticClass: "py-1",
    attrs: {
      "color": "indigo lighten-1",
      "dark": ""
    }
  }, [_c(VCardTitle, {
    staticClass: "d-flex align-center justify-space-between pt-0"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Loading...")]), _c(VProgressLinear, {
    staticClass: "mb-0",
    attrs: {
      "indeterminate": "",
      "color": "white"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };