import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'

const debounce = (fn, wait) => {
    let timer
    return function(...args) {
        if(timer) {
            clearTimeout(timer)
        }

        const context = this
        timer = setTimeout(()=>{
            fn.apply(context, args)
        }, wait)
    }
}

const logOut    = debounce(() => { 
    store.dispatch('logout', { redirect: router.currentRoute.path }) 
}, 100)
const alert     = debounce((status, message) => { 
    store.dispatch('alerts/add', { status, message }) 
}, 100)

axios.defaults.baseURL          = process.env.VUE_APP_ROOT_API
axios.defaults.withCredentials  = true

axios.interceptors.response.use((res) => {
    const { status = 'success', message } = res.data
    if (message) {
        alert(status, message)
    }
    return Promise.resolve(res)
}, (err) => {
    if (err.response?.status === 401) {
        logOut()
    } else if (err.response?.status === 403) {
        router.push('/')
    }

    const { status = 'error', message = 'Internal server error' } = parseError(err.response)
    alert(status, message)
    return Promise.reject(err)
})

function parseError(res) {
    if (res?.status === 404) {
        return { status: 'error', message: 'Recurso no encontrado' }
    }
    
    return res.data
}
Vue.mixin({
    methods: {
        // TODO: documentate
        GET(url, args = {}, lKey = 'loading') {
            this[lKey] = true

            if (args.loader !== false) {
                args.loader = true
                store.dispatch('loader/show')
            }
            return new Promise((resolve, reject) => {
                axios.get(url, args)
                .then((res) => {
                    // unpack into this
                    Object.keys(res.data).forEach((key) => {
                        if (Object.hasOwn(this, key)) {
                            this[key] = res.data[key]
                        }
                    })
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                }).finally(() => {
                    this[lKey]=false
                    if (args.loader) {
                        store.dispatch('loader/hide')
                    }
                })
            })   
        },
        POST(url, args = {}, opts = {}, lKey = 'loading') {
            this[lKey] = true
            if (args.emit !== false) {
                args.emit = true
            }
            return new Promise((resolve, reject) => {
                axios.post(url, args, opts)
                .then((res) => {
                    // unpack into this
                    Object.keys(res.data).forEach((key) => {
                        if (Object.hasOwn(this, key)) {
                            this[key] = res.data[key]
                        }
                    })
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                }).finally(() => {
                    this[lKey]=false
                    if (args.emit) {
                        this.$emit('refresh')
                    }
                })
            })   
        },
        PUT(url, args = {}, lKey = 'loading') {
            this[lKey] = true
            return new Promise((resolve, reject) => {
                axios.put(url, args)
                .then((res) => {
                    // unpack into this
                    Object.keys(res.data).forEach((key) => {
                        if (Object.hasOwn(this, key)) {
                            this[key] = res.data[key]
                        }
                    })
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                }).finally(() => {
                    this[lKey]=false
                    this.$emit('refresh')
                })
            })   
        },
        DELETE(url, args = {}, lKey = 'loading') {
            this[lKey] = true
            return new Promise((resolve, reject) => {
                axios.delete(url, args)
                .then((res) => {
                    // unpack into this
                    Object.keys(res.data).forEach((key) => {
                        if (Object.hasOwn(this, key)) {
                            this[key] = res.data[key]
                        }
                    })
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                }).finally(() => {
                    this[lKey]=false
                    this.$emit('refresh')
                })
            })   
        }
    }
})

export default axios