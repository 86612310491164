import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Breadcrumbs',
  data: () => ({}),
  mounted() {
    this.updateBreadcrumbs(this.$route.meta.breadcrumbs);
  },
  methods: {
    ...mapActions(['updateBreadcrumbs']),
    sliceBreadcrumb(text, n = 30) {
      return text.length <= n ? text : text.slice(0, n) + '...';
    }
  },
  computed: {
    ...mapGetters(['breadcrumbs'])
  }
};