/* unplugin-vue-components disabled */export default {
  name: 'Navbar',
  data: () => ({
    selectedYear: -1
  }),
  mounted() {
    this.selectedYear = this.years.indexOf(this.year);
  },
  computed: {
    ...mapGetters(['year', 'years']),
    localYear: {
      get() {
        return this.year;
      },
      set(v) {
        this.updateYear(v);
      }
    },
    version() {
      return process.env.VUE_APP_VERSION;
    }
  },
  methods: {
    ...mapActions(['updateYear', 'toggleSidebar'])
  }
};