import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    alert: {
      type: Object,
      default: () => ({})
    },
    timeout: {
      type: Number,
      default: 100
    },
    dismissable: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },
  name: 'Alert',
  data: inst => ({
    show: true,
    timer: null,
    currentTime: inst.timeout
  }),
  created() {
    if (this.dismissable) {
      this.startTimer();
    }
  },
  methods: {
    ...mapActions('alerts', ['dismiss']),
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime -= 2.5;
        if (this.currentTime <= 0) {
          this.dismiss(this.alert.id);
        }
      }, 100);
    }
  },
  computed: {
    ...mapGetters('status', ['getKeyByStatus']),
    color() {
      return this.getKeyByStatus(this.alert.status);
    },
    icon() {
      return this.getKeyByStatus(this.alert.status, 'icon');
    }
  }
};