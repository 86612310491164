import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VAlert, {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "text": "",
      "outlined": _vm.outlined,
      "dense": "",
      "color": _vm.color
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c(VRow, {
    attrs: {
      "align": "center"
    }
  }, [_c(VCol, {
    staticClass: "shrink"
  }, [_c(VIcon, {
    attrs: {
      "color": _vm.color
    },
    domProps: {
      "textContent": _vm._s(_vm.icon)
    }
  })], 1), _c(VCol, {
    staticClass: "grow font-weight-medium text-left pl-0"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.alert.message)
    }
  })]), _vm.dismissable ? _c(VCol, {
    staticClass: "shrink"
  }, [_c(VBtn, {
    attrs: {
      "color": _vm.color,
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.dismiss(_vm.alert.id);
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-close")])], 1)], 1) : _vm._e()], 1), _vm.dismissable ? _c(VProgressLinear, {
    attrs: {
      "value": _vm.currentTime,
      "color": _vm.color,
      "absolute": "",
      "bottom": "",
      "striped": ""
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };