import { VCol } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCol, {
    staticClass: "alerts pl-md-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, _vm._l(_vm.alerts, function (a) {
    return _c('div', {
      key: `alert-${a.id}`,
      staticClass: "white elevation-2 rounded-sm",
      attrs: {
        "transition": "slide-x-transition"
      }
    }, [_c('Alert', {
      attrs: {
        "alert": a,
        "dismissable": ""
      }
    })], 1);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };