import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueMask from 'v-mask';
import DatetimePicker from 'vuetify-datetime-picker';
require('@/mixins/axios.js');
require('@/mixins/utils.js');
require('@/assets/styles/main.css');
Vue.use(VueMask);
Vue.use(DatetimePicker);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');