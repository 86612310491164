export const alerts = {
    namespaced: true,
    state: {
        alerts: []
    },
    actions: {
        add({ commit }, alert) {
            commit('add', alert)
        },
        dismiss({ commit }, id) {
            commit('dismiss', id)
        
        }
    },
    mutations: {
        add(state, alert) {
            const id = `${Date.now().toString(16)}${Math.random().toString(16)}`
            state.alerts.push({ id, ...alert })
        },
        dismiss(state, id) {
            state.alerts = state.alerts.filter((a) => id !== a.id)
        }
    }
}